// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./icons/rewind.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./icons/forward.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bmpui-ui-rewindbutton {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
}

.bmpui-ui-forwardbutton {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: contain;
}

.bmpui-ui-rewindbutton:hover, .bmpui-ui-forwardbutton:hover {
    -webkit-filter: drop-shadow(0 0 1px #fff);
    filter: drop-shadow(0 0 1px #fff);
}

.bmpui-ui-watermark {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/players/styles.css"],"names":[],"mappings":"AAAA;IACI,yDAAyC;IACzC,wBAAwB;AAC5B;;AAEA;IACI,yDAA0C;IAC1C,wBAAwB;AAC5B;;AAEA;IACI,yCAAyC;IACzC,iCAAiC;AACrC;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".bmpui-ui-rewindbutton {\n    background-image: url(./icons/rewind.svg);\n    background-size: contain;\n}\n\n.bmpui-ui-forwardbutton {\n    background-image: url(./icons/forward.svg);\n    background-size: contain;\n}\n\n.bmpui-ui-rewindbutton:hover, .bmpui-ui-forwardbutton:hover {\n    -webkit-filter: drop-shadow(0 0 1px #fff);\n    filter: drop-shadow(0 0 1px #fff);\n}\n\n.bmpui-ui-watermark {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
