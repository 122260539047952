import { PlayerEventBase } from 'bitmovin-player';

import { BasePlaybackEvent, PlaybackEvents, eventsMap, playerEventDiscriminatorMap } from './model/events.model';
import { BasePlayer } from './model/player.model';
import { Player } from './model/playback.model';

export type Listener = (event: BasePlaybackEvent) => void;

type ListenersMap = {
  [Type in PlaybackEvents]?: Listener[];
};

export class PlayerEventMapper {
  constructor(private readonly playerType: Player) {}

  /**
   * Maps event coming from bitmovin instance to BasePlaybackEvent
   *
   * @param {PlayerEventBase} event - bitmovin native event
   * @returns {BasePlaybackEvent}
   */
  private mapBitmovinEvents(event: PlayerEventBase): BasePlaybackEvent {
    const eventMap = eventsMap[this.playerType];

    const playbackEvent = Object.entries(eventMap).find(
      ([_, value]) => value === event[playerEventDiscriminatorMap[this.playerType]],
    )[0] as PlaybackEvents;

    return {
      type: playbackEvent,
      timestamp: event.timestamp,
    };
  }

  /**
   * Maps event coming from player instance (e.g. bitmovin or videojs) to BasePlaybackEvent
   *
   * @param {unknown} event - player native event
   * @returns {BasePlaybackEvent}
   */
  map(event: unknown): BasePlaybackEvent {
    return this.mapBitmovinEvents(event as PlayerEventBase);
  }
}

/**
 * Manages all player event subscriptions, does event mapping (player event -> playback event) and fires correct listeners with mapped payload.
 */
export class PlayerEventManager {
  private mapper: PlayerEventMapper;
  private listeners: ListenersMap = {};

  constructor(private readonly playerType: Player, private player: BasePlayer) {
    this.mapper = new PlayerEventMapper(playerType);
  }

  /**
   * Fired on player native event and fires correct event listeners according to listeners map
   * @private
   * @param {unknown} event - player event
   * @returns {void}
   */
  private genericEventHandler(event: unknown) {
    const eventMap = eventsMap[this.playerType];

    const playbackEvent = Object.entries(eventMap).find(
      ([_, value]) => value === event[playerEventDiscriminatorMap[this.playerType]],
    )[0];

    if (playbackEvent) {
      this.listeners[playbackEvent]?.forEach((listener) => listener(this.mapper.map(event)), this);
    }
  }

  private subscribe(event: PlaybackEvents, listener: Listener): boolean {
    const listeners = this.listeners[event] || [];

    this.listeners[event] = [...listeners, listener];

    return !!listeners.length;
  }

  /**
   * Subscribes to player event
   *
   * @param {PlaybackEvents} event - playback event
   * @param {(event: BasePlaybackEvent) => void} listener - event handler
   * @returns {void}
   */
  on(event: PlaybackEvents, listener: Listener) {
    const playerEvent = eventsMap[this.playerType][event];

    if (playerEvent) {
      const isSubscribed = this.subscribe(event, listener);

      if (!isSubscribed) {
        this.player.listenOn(playerEvent, this.genericEventHandler.bind(this));
      }
    }
  }

  /**
   * Unsubscribes from player event
   *
   * @param {PlaybackEvents} event - playback event
   * @param {(event: BasePlaybackEvent) => void} listener - event handler
   * @returns {void}
   */
  off(event: PlaybackEvents, listener: Listener) {
    const playerEvent = eventsMap[this.playerType][event];

    if (playerEvent) {
      const listeners = this.listeners[event] || [];

      const newListeners = listeners.filter((l) => l !== listener);
      this.listeners[event] = [...newListeners];

      if (!newListeners.length) {
        this.player.off(playerEvent, this.genericEventHandler);
      }
    }
  }

  /**
   * Removes all event listeners and unsubscribes all events from player instance
   *
   * @param {PlaybackEvents} event - playback event
   * @param {Listener} listener - event handler
   * @returns {void}
   */
  dispose() {
    Object.keys(this.listeners).forEach((key) => {
      const playerEvent = eventsMap[this.playerType][key];

      this.player.off(playerEvent, this.genericEventHandler);
    }, this);

    this.listeners = {};
  }

  /**
   * Refreshes all existing listeners (e.x. to be used after player got re-initialized)
   *
   * @param {BasePlayer} player - player instance
   * @returns {void}
   */
  refresh(player: BasePlayer) {
    this.player = player;

    Object.entries(this.listeners).forEach(([event]) => {
      const playerEvent = eventsMap[this.playerType][event];

      if (playerEvent) {
        this.player.listenOn(playerEvent, this.genericEventHandler.bind(this));
      }
    });
  }
}
