import { ErrorType, ResumeErrorName } from '../model/error.model';
import { TimestampRecord } from '../model/resume.model';
import { constructErrorObject } from '../utils/error.utils';

/**
 * @hidden not to be exposed to external clients in generated SDK documentation.
 *
 * Class representing the resume capability of a video player allowing clients to playback from saved/last watched
 * positions in videos across devices. Implementation is through a web socket.
 *
 * This is utilised within the player embedding resume functionality e.g 'Bitmovin' class.
 * @see Bitmovin
 */
export class Resume {
  static webSocket: WebSocket;
  static jwtToken: string;
  static connected = false;

  private static intervalId: NodeJS.Timer;

  /**
   * Connect a web socket to resume.
   *
   * @param token JWT token for authentication.
   * @static
   */
  public static connect(token: string): void {
    try {
      let webSocketUrl = process.env.RESUME_URL;

      if (!webSocketUrl) {
        throw 'No Websocket url provided';
      }

      webSocketUrl = webSocketUrl.concat('?authorisation=', token);

      if (this.connected) {
        // Close the currently open connection as a new connection will be opened (normal closure).
        this.onClosedEvent({ code: 1000, reason: 'Connect attempt so close current open connection' });
      }

      this.jwtToken = token;
      this.webSocket = new WebSocket(webSocketUrl);
      this.webSocket.onopen = (evt) => this.onConnectedEvent(evt);
      this.webSocket.onclose = (evt) => this.onClosedEvent(evt);
      this.webSocket.onerror = (evt) => this.onErrorEvent(evt);
      this.connected = true;
    } catch (error) {
      throw constructErrorObject(ErrorType.ResumeError, ResumeErrorName.ConnenctionError, `Failed to configure Resume`);
    }
  }

  private static onConnectedEvent(evt): void {
    console.debug('Websocket opened event:', evt);
    this.createHeartbeat();
  }

  public static onClosedEvent(evt): void {
    console.debug('Websocket closed event:', evt);
    this.connected = false;
    clearInterval(this.intervalId);

    if (this.webSocket?.readyState === WebSocket.OPEN) {
      this.webSocket.close();
    }
  }

  private static onErrorEvent(evt): void {
    console.error('Websocket error event:', evt);
    this.onClosedEvent(evt);
  }

  private static createHeartbeat(): void {
    const heartbeatMessage = {
      action: 'heartbeat',
    };

    const heartbeat = process.env.RESUME_HEARTBEAT_MS ? parseInt(process.env.RESUME_HEARTBEAT_MS) : 30000;

    this.intervalId = setInterval(() => Resume.sendMessage(heartbeatMessage as TimestampRecord), heartbeat);
  }

  /**
   * Send a message to the resume web socket.
   *
   * @param payload timestamp record.
   * @static
   */
  public static sendMessage(
    payload: Pick<TimestampRecord, 'action' | 'entryId' | 'lastActionAt' | 'timestamp' | 'duration'>,
  ) {
    if (!this.connected) {
      throw 'attempted to send message on an unconnected socket';
    }

    this.webSocket.send(JSON.stringify(payload));
  }
}
