import { PlayerEvent } from 'bitmovin-player';

/**
 * Playback events consumer can subscribe to
 */

export enum PlaybackEvents {
  PlaybackPlayerReady = 'PlaybackPlayerReady',
  PlaybackPlayerDestroy = 'PlaybackPlayerDestroy',
  PlaybackPlayerSeeked = 'PlaybackPlayerSeeked',
  PlaybackPlayerTimeShifted = 'PlaybackPlayerTimeShifted',
  PlaybackPlayerFinished = 'PlaybackPlayerFinished',
  PlaybackPlay = 'PlaybackPlay',
  PlaybackPlaying = 'PlaybackPlaying',
  PlaybackPaused = 'PlaybackPaused',
  PlaybackSeek = 'PlaybackSeek',
  PlaybackTimeShift = 'PlaybackTimeShift',
}

export interface BasePlaybackEvent {
  type: PlaybackEvents;
  timestamp: number;
}

const playbackBitmovinEventsMap: Record<PlaybackEvents, PlayerEvent> = {
  [PlaybackEvents.PlaybackPlay]: PlayerEvent.Play,
  [PlaybackEvents.PlaybackPaused]: PlayerEvent.Paused,
  [PlaybackEvents.PlaybackPlayerDestroy]: PlayerEvent.Destroy,
  [PlaybackEvents.PlaybackPlayerFinished]: PlayerEvent.PlaybackFinished,
  [PlaybackEvents.PlaybackPlayerReady]: PlayerEvent.Ready,
  [PlaybackEvents.PlaybackPlayerSeeked]: PlayerEvent.Seeked,
  [PlaybackEvents.PlaybackPlayerTimeShifted]: PlayerEvent.TimeShifted,
  [PlaybackEvents.PlaybackPlaying]: PlayerEvent.Playing,
  [PlaybackEvents.PlaybackSeek]: PlayerEvent.Seek,
  [PlaybackEvents.PlaybackTimeShift]: PlayerEvent.TimeShift,
} as const;

export const playerEventDiscriminatorMap: Record<string, string> = {
  bitmovin: 'type',
} as const;

export const eventsMap: Record<string, Record<PlaybackEvents, unknown>> = {
  bitmovin: playbackBitmovinEventsMap,
} as const;
