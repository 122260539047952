import { ErrorType, PlaybackErrorName, ResumeErrorName, SDKErrorName } from '../model/error.model';

/** @ignore */
export const constructErrorObject = (
  type: ErrorType,
  name: PlaybackErrorName | SDKErrorName | ResumeErrorName,
  message: string,
) => {
  console.error({ errorType: type, name, message });

  return { errorType: type, name, message };
};
