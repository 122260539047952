export interface Error {
  message: string;
  type: ErrorType;
  name: SDKErrorName | PlaybackErrorName | ResumeErrorName;
}

export enum ErrorType {
  SDKError = 'SDK_ERROR',
  PlaybackError = 'PLAYBACK_ERROR',
  EntitlementsError = 'ENTITLEMENTS_ERROR',
  ResumeError = 'RESUME_ERROR',
}

export enum SDKErrorName {
  ConfigurationNotRetrieved = 'CONFIGURATION_NOT_RETRIEVED',
  SdkNotInitialized = 'SDK_NOT_INITIALIZED',
  PlayerNotInitialized = 'PLAYER_NOT_INITIALIZED',
  RequiredParametersNotPassed = 'REQUIRED_PARAMETERS_NOT_PASSED',
  SessionTokenNotRetrieved = 'SESSION_TOKEN_NOT_RETRIEVED',
  NoPlayerExists = 'NO_PLAYER_EXISTS',
  NoVideoDetails = 'NO_VIDEO_DETAILS',
  NoPlaybackDetails = 'NO_PLAYBACK_DETAILS',
  UnknownParameter = 'UNKNOWN_PARAMETER',
  InternalError = 'INTERNAL_ERROR',
}

export enum PlaybackErrorName {
  InvalidApiKey = 'INVALID_API_KEY',
  NoActiveSession = 'NO_ACTIVE_SESSION',
  NoSubscription = 'NO_SUBSCRIPTION',
  NoAuthentication = 'NOT_AUTHENTICATED',
  NoEntitlement = 'NO_ENTITLEMENT',
  NotFound = 'NO_ENTITY_EXIST',
  Error = 'BAD_REQUEST_ERROR',
  TooManyRequests = 'TOO_MANY_REQUESTS',
  TooManyDevices = 'TOO_MANY_DEVICES',
  HeaderError = 'HEADER_ERROR',
  SiteError = 'SITE_NOT_FOUND',
  TokenError = 'TOKEN_ERROR',
  ConfigError = 'CONFIGURATION_ERROR',
  ApiKeyError = 'API_KEY_ERROR',
  PartnerError = 'MP_PARTNER_ERROR',
}

export enum ResumeErrorName {
  ConnenctionError = 'CONNECTION_ERROR',
}
